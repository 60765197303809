<template>
  <div v-if="!isPageVisible">
    <b-card no-body style="height: 150px" class="d-flex justify-content-center align-items-center">
      <h3>Resource Not Available</h3>
    </b-card>
  </div>
  <div v-else-if="user_type === 'agent'">
    <b-card no-body style="height: 150px" class="d-flex justify-content-center align-items-center">
      <h3>"Previledge access only to Aliff Franchise."</h3>
    </b-card>
  </div>
  <div v-else>
    <b-card>
      <b-form-input placeholder="Search..." v-model="search" @input="onSearch" />
    </b-card>
    <b-card class="bg-white p-2">
      <div class="row row-cols-3">
        <div v-for="(training, t_index) in trainings" :key="'training_' + t_index" class="mt-1 col w-100"
          style="cursor: pointer" @click="goToTraining(training)">
          <div class="h-100">
            <div class="col h-100 p-1">
              <article class="postcard dark red h-100">
                <a class="postcard__img_link overflow-hidden" href="#">
                  <div class="overflow-hidden w-100">
                    <img class="postcard__img img-fluid" :src="FILESURL + training.ft_image" alt="Image Title" />
                  </div>
                </a>
                <div class="postcard__text">
                  <h1 class="postcard__title red">
                    <span class="text-dark">{{ training.ft_name }}</span>
                  </h1>
                  <ul class="postcard__tagbox justify-content-start mb-0">
                    <li class="tag__item bg-success">
                      <i class="fas fa-clock mr-2"></i>{{ training.ft_duration }}
                    </li>
                  </ul>
                  <div class="postcard__bar"></div>
                  <div class="postcard__preview-txt text-dark">
                    {{ training.ft_text }}
                  </div>
                  <h5 class="mt-1">Videos in this course</h5>
                  <div class="d-flex">
                    <div v-for="(video, t_index) in training.videos" :key="'video_index_' + t_index">
                      <ul class="postcard__tagbox justify-content-start">
                        <li class="tag__item">
                          {{ video.ftv_name }} -
                          {{ video.ftv_duration }}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="videos-in-course d-flex align-items-center" style="gap: 10px">
                    <div></div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BAvatar,
  BButton,
  BInputGroup,
  BFormInput,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { FILESURL } from "@/config";
import CommonServices from "@/apiServices/CommonServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
    BInputGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: "",
      trainings: [],
      FILESURL,
      selectedAnswer: null,
      trainingIndex: null,
      questionIndex: null,
      user_type: null,
      debounce: null,
    };
  },
  computed: {
    question() {
      if (this.trainingIndex !== null && this.questionIndex !== null) {
        return this.trainings[this.trainingIndex].questions[this.questionIndex];
      }
      return null;
    },
    isPageVisible() {
      const user_details = this.$store.getters["user/getUserDetails"];
      this.user_type = user_details.user_type
      if (user_details && Array.isArray(user_details.allocated_resources)) {

        const allowcatedResource = user_details.allocated_resources.find((elem) => elem === 'training_hub')
        if (allowcatedResource) {
          this.getTrainings();

          return true
        }
        return false
      }
      return false
    },
  },
  methods: {
    async goToTraining(training) {
      this.$router.push({
        name: "Franchise Training Video Details",
        params: { ft_id: training.ft_id },
      });
    },
    onSearch() {
      if (this.debounce) {
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.onFilterChange();
      }, 1000);
    },
    onFilterChange() {
      this.$nextTick(() => {
        this.getTrainings();
      });
    },
    async getTrainings() {
      try {
        const res = await CommonServices.getFranchiseTrainings({
          search: this.search,
        });
        if (res.data.status) {
          this.trainings = res.data.data;
        }
      } catch (error) {
        console.error("Error in getTrainings ", error);
      }
    },
    async nextQuestion() {
      if (!this.selectedAnswer) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Please choose an option",
            icon: "EditIcon",
            variant: "secondary",
          },
        });
        return;
      }

      await CommonServices.setQuestionResponse({ fta_id: this.selectedAnswer });

      this.questionIndex++;
      this.selectedAnswer = null;
    },
    async finishQuiz() {
      if (!this.selectedAnswer) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Please choose an option",
            icon: "EditIcon",
            variant: "secondary",
          },
        });
        return;
      }

      await CommonServices.setQuestionResponse({ fta_id: this.selectedAnswer });

      this.$refs["quiz-modal-ref"].hide();
      this.resetQuizModal();
    },
    async getQuizResult() {
      try {
        const res = await Fra;
      } catch (error) {
        console.error(`Error in getQuizResult `, error);
      }
    },
    resetQuizModal() {
      this.trainingIndex = null;
      this.questionIndex = null;
      this.selectedAnswer = null;
    },
    showQuizModal(t_index) {
      this.questionIndex = 0;
      this.trainingIndex = t_index;
      this.$refs["quiz-modal-ref"].show();
    },
  },
  beforeMount() {
  },
};
</script>
<style>
.video-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  width: 100%;
}

.video-container {
  /* margin: 10px;  */
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  height: 320px;
}

.training-title {
  text-decoration: underline;
}

/* .video-container {
  height: auto;
  width: 70%;
  border-radius: 10px;
} */

.dashboard__heading {
  color: #002884;
  font-weight: bold;
  margin-bottom: 1.2rem;
}

.student-dashboard .card-title {
  color: #000000;
  font-weight: 600;
}

.primary-color {
  color: #00d09c;
}

.min-height {
  min-height: 60vh;
}

.container {
  display: flex;
  height: 50vh;
  overflow: hidden;
}

.scrollable {
  height: 100%;
  overflow-y: auto;
}

.map-column {
  width: 60%;
}

iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column-reverse;
    height: auto;
  }

  .scrollable {
    height: auto;
    overflow-y: visible;
  }

  .map-column {
    width: 100%;
  }
}
</style>
